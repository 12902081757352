exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-diagnostyka-js": () => import("./../../../src/pages/diagnostyka.js" /* webpackChunkName: "component---src-pages-diagnostyka-js" */),
  "component---src-pages-dlaczego-my-js": () => import("./../../../src/pages/dlaczego-my.js" /* webpackChunkName: "component---src-pages-dlaczego-my-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-opinie-js": () => import("./../../../src/pages/opinie.js" /* webpackChunkName: "component---src-pages-opinie-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-poznaj-nas-js": () => import("./../../../src/pages/poznaj-nas.js" /* webpackChunkName: "component---src-pages-poznaj-nas-js" */),
  "component---src-pages-promocje-js": () => import("./../../../src/pages/promocje.js" /* webpackChunkName: "component---src-pages-promocje-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-szkolenia-index-js": () => import("./../../../src/pages/szkolenia/index.js" /* webpackChunkName: "component---src-pages-szkolenia-index-js" */),
  "component---src-pages-szkolenia-szkolenia-stacjonarne-js": () => import("./../../../src/pages/szkolenia/szkolenia-stacjonarne.js" /* webpackChunkName: "component---src-pages-szkolenia-szkolenia-stacjonarne-js" */),
  "component---src-pages-zabiegi-js": () => import("./../../../src/pages/zabiegi.js" /* webpackChunkName: "component---src-pages-zabiegi-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-templates-single-course-js": () => import("./../../../src/templates/single-course.js" /* webpackChunkName: "component---src-templates-single-course-js" */),
  "component---src-templates-single-diagnosis-js": () => import("./../../../src/templates/single-diagnosis.js" /* webpackChunkName: "component---src-templates-single-diagnosis-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-specialist-js": () => import("./../../../src/templates/single-specialist.js" /* webpackChunkName: "component---src-templates-single-specialist-js" */),
  "component---src-templates-single-testimonial-js": () => import("./../../../src/templates/single-testimonial.js" /* webpackChunkName: "component---src-templates-single-testimonial-js" */)
}

